import { NextPage } from 'next';
import Head from 'next/head';
import { generateFullPageMetadata } from '@MetaHead';
import PdfViewer from '@pdfViewer';
import PageHeader from '@PageHeader';
import  Body  from '@Body'; // Assuming you have this component



const RootPage: NextPage = () => {
  const pageData = {
    "href": "https://thedailydialectics.com",
    "BASE_URL": "https://thedailydialectics.com",
    "share_url": "https://thedailydialectics.com/index",
    "description": "In an age where truths are akin to fiction in the minds of the masses, fiction is a key component in societal control. Reprogram your programmers programming; join the dialectic.",
    "thumbnail": "/var/www/thedailydialectics/public/imgs/justified-true-belief-venn-diagram",
    "ext": "",
    "keywords_str": "science,nikola-tesla,dialectic,goubau,sommerfeld,electromagnetic,quantum,ufo",
    "media": [],
    "title": "Home Page | The Daily Dialectics | TDD",
    "url": "/index",
    "content": "<h1>Welcome to The Daily Dialectics</h1>", // Added content
    "imageData": {
      "alt": "Venn diagram illustrating the justified true belief theory of knowledge",
      "caption": "Diagram showing the relationship between propositions, truths, beliefs, and knowledge",
      "keywords_str": "knowledge, justified true belief, philosophy, epistemology, venn diagram",
      "filename": "justified-true-belief-venn",
      "ext": ".webp",
      "title": "Justified True Belief Venn Diagram | The Daily Dialectics",
      "dimensions": {
          "width": 1200,
          "height": 627
      },
      "file_size": "50KB",
      "license": "CC BY-SA 4.0",
      "attribution": "Created by thedailydialectics for educational purposes",
      "longdesc": "A Venn diagram with three overlapping circles labeled 'Propositions,' 'Truths,' and 'Beliefs.' The intersection of 'Truths' and 'Beliefs' forms 'Poorly justified true beliefs,' and within that, a smaller circle represents 'Knowledge' as justified true belief.",
      "schema": {
          "@context": "https://schema.org",
          "@type": "ImageObject",
          "name": "Justified True Belief Venn Diagram",
          "description": "A Venn diagram illustrating the justified true belief theory of knowledge, showing the overlap of propositions, truths, and beliefs, with knowledge as the intersection of justified true beliefs.",
          "url": "https://thedailydialectics.com/imgs/justified-true-belief-venn-diagram/justified-true-belief-venn-diagram.webp",
          "contentUrl": "https://thedailydialectics.com/imgs/justified-true-belief-venn-diagram/justified-true-belief-venn-diagram.webp",
          "width": 1200,
          "height": 627,
          "license": "https://creativecommons.org/licenses/by-sa/4.0/",
          "creator": {
              "@type": "Organization",
              "name": "thedailydialectics"
          },
          "datePublished": "2025-04-01"
      },
      "social_meta": {
          "og:image": "https://thedailydialectics.com/imgs/justified-true-belief-venn-diagram/justified-true-belief-venn-diagram.webp",
          "og:image:alt": "Venn diagram illustrating the justified true belief theory of knowledge",
          "twitter:card": "summary_large_image",
          "twitter:image": "https://thedailydialectics.com/imgs/justified-true-belief-venn-diagram/justified-true-belief-venn-diagram.webp"
      }
  }
  
  };

  const metadataHTML = generateFullPageMetadata(pageData, pageData.imageData).toHTML(); // Generate metadata

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="description" content="Explore content from The Daily Dialectics." />
        <meta name="keywords" content={pageData.keywords_str} />
        <link rel="icon" href="/imgs/favicon.ico" type="image/x-icon" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="noarchive" />
        <meta name="yahooContent" content="article" />
        <meta name="author" content="The Daily Dialectics Team" />
        <meta name="revisit-after" content="7 days" />
        <meta name="rating" content="General" />
        <meta name="distribution" content="global" />
        <meta property="og:title" content={pageData.title} />
        <meta property="og:description" content={pageData.description} />
        <meta property="og:url" content={pageData.share_url} />
        <meta property="og:image" content={pageData.imageData.social_meta['og:image']} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="The Daily Dialectics" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageData.title} />
        <meta name="twitter:description" content={pageData.description} />
        <meta name="twitter:image" content={pageData.imageData.social_meta['twitter:image']} />
        <meta name="twitter:image:type" content="image/webp" />
        <meta name="twitter:site" content="@thedailydialectics" />
        <meta name="twitter:creator" content="@thedailydialectics" />
        <meta name="twitter:domain" content="thedailydialectics.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="application-name" content="The Daily Dialectics" />
        <meta name="theme-color" content="#FFFFFF" />
        <meta name="color-scheme" content="light" />
        <link rel="canonical" href={pageData.share_url} />
        <div dangerouslySetInnerHTML={{ __html: metadataHTML }} />
      </Head>
      <PageHeader meta={pageData} baseUrl={pageData.BASE_URL} />
      <Body meta={pageData} pdfViewer={true} />
    </>
  );
};

export default RootPage;