import { ImageData, PageData } from '@interfaces';
/**
 * Removes characters from the beginning of the string
 * if they are found in the list of characters.
 *
 * @param str - The input string.
 * @param listObjects - A string or an array of characters to remove.
 * @returns The modified string.
 */
export function eatInner(str: string, listObjects: string | string[]): string {
  if (!Array.isArray(listObjects)) {
    listObjects = [listObjects];
  }
  // Ensure str is a string
  str = String(str);

  // Remove characters from the beginning while they are in listObjects
  while (str.length > 0 && listObjects.includes(str[0])) {
    str = str.slice(1);
  }
  return str;
}

/**
 * Removes characters from the end of the string
 * if they are found in the list of characters.
 *
 * @param str - The input string.
 * @param listObjects - A string or an array of characters to remove.
 * @returns The modified string.
 */
export function eatOuter(str: string, listObjects: string | string[]): string {
  if (!Array.isArray(listObjects)) {
    listObjects = [listObjects];
  }
  // Ensure str is a string
  str = String(str);

  // Remove characters from the end while they are in listObjects
  while (str.length > 0 && listObjects.includes(str[str.length - 1])) {
    str = str.slice(0, -1);
  }
  return str;
}

/**
 * Removes characters from both the beginning and the end of the string
 * if they are found in the list of characters.
 *
 * @param str - The input string.
 * @param listObjects - A string or an array of characters to remove.
 * @returns The modified string.
 */
export function eatAll(str: string, listObjects: string | string[]): string {
  return eatOuter(eatInner(str, listObjects), listObjects);
}
//src/components/MetaData/Head/MetaHead
/**
 * Processes keywords by checking if keywords is a string and splitting it.
 * Then cleans each keyword using `eatAll` with a set of characters to remove.
 *
 * @param keywords - The keywords as a comma-separated string or as an array.
 * @returns An array of cleaned keywords.
 */
export function processKeywords(keywords: string | string[]): string[] {
  let keywordArray: string[];

  // If keywords is a string, split it on commas
  if (typeof keywords === "string") {
    keywordArray = keywords.split(",");
  } else {
    keywordArray = keywords;
  }

  // Clean each keyword by removing unwanted characters
  return keywordArray.map(keyword =>
    eatAll(keyword, [",", "\n", "\t", " ", "#"])
  );
}
export function generateFullPageMetadata(pageData:PageData, imageData:ImageData) {
  // Extract core page properties
  const {
      BASE_URL: baseUrl,
      href,
      title: pageTitle,
      share_url: shareUrl,
      description: pageDescription,
      keywords_str: pageKeywordsStr,
      thumbnail
  } = pageData;

  // Extract core image properties
  const {
      alt: imageAlt,
      caption: imageCaption,
      keywords_str: imageKeywordsStr,
      filename: imageFilename,
      ext: imageExt,
      title: imageTitle,
      dimensions,
      file_size: imageFileSize,
      license: imageLicense,
      attribution: imageAttribution,
      longdesc: imageLongDesc,
      schema: imageSchema,
      social_meta: imageSocialMeta
  } = imageData;

  // Construct full image URL from thumbnail path if needed
  const imageUrl = `${baseUrl}${thumbnail}/${imageFilename}.${imageExt}`.replace('/var/www/thedailydialectics/public', '');

  // Merge keywords from page and image, removing duplicates
  let combinedKeywords = [...processKeywords(imageKeywordsStr),...processKeywords(pageKeywordsStr)]
  combinedKeywords = processKeywords(combinedKeywords)
  // Construct the full metadata object
  const fullMetadata = {
      // Open Graph (OG) Metadata
      openGraph: {
          'og:type': 'article',
          'og:title': pageTitle,
          'og:description': pageDescription,
          'og:url': shareUrl,
          'og:image': imageSocialMeta['og:image'] || imageUrl,
          'og:image:alt': imageSocialMeta['og:image:alt'] || imageAlt,
          'og:image:width': dimensions.width.toString(),
          'og:image:height': dimensions.height.toString(),
          'og:image:type': `image/${imageExt}`,
          'og:site_name': 'The Daily Dialectics',
          'og:locale': 'en_US', // Assuming English; adjust as needed
          'og:updated_time': imageSchema.datePublished || new Date().toISOString().split('T')[0]
      },

      // Twitter Card Metadata
      twitter: {
          'twitter:card': imageSocialMeta['twitter:card'] || 'summary_large_image',
          'twitter:title': pageTitle,
          'twitter:description': pageDescription,
          'twitter:image': imageSocialMeta['twitter:image'] || imageUrl,
          'twitter:image:alt': imageAlt,
          'twitter:creator': '@DailyDialectics', // Hypothetical handle; adjust as needed
          'twitter:site': '@DailyDialectics' // Adjust as needed
      },

      // Google (Schema.org) Structured Data
      schemaOrg: {
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          'name': pageTitle,
          'description': pageDescription,
          'url': shareUrl,
          'keywords': combinedKeywords,
          'inLanguage': 'en', // Assuming English; adjust as needed
          'datePublished': imageSchema.datePublished || '2025-03-30', // Use image date or default
          'publisher': {
              '@type': 'Organization',
              'name': 'The Daily Dialectics'
          },
          'image': {
              '@type': 'ImageObject',
              'name': imageTitle,
              'description': imageSchema.description || imageCaption,
              'url': imageSchema.url || imageUrl,
              'contentUrl': imageSchema.contentUrl || imageUrl,
              'width': dimensions.width,
              'height': dimensions.height,
              'license': imageSchema.license || 'https://creativecommons.org/licenses/by-sa/4.0/',
              'creator': imageSchema.creator || { '@type': 'Organization', 'name': 'The Daily Dialectics' },
              'fileFormat': `image/${imageExt}`,
              'contentSize': imageFileSize,
              'caption': imageCaption,
              'alternateName': imageFilename
          }
      },

      // General Meta Tags
      meta: {
          'description': pageDescription,
          'keywords': combinedKeywords,
          'image': imageSocialMeta['og:image'] || imageUrl,
          'robots': 'index, follow', // Default; adjust for SEO preferences
          'author': imageAttribution.split('Created by ')[1] || 'The Daily Dialectics',
          'copyright': `© ${new Date().getFullYear()} The Daily Dialectics under ${imageLicense}`,
          'content-language': 'en', // Assuming English; adjust as needed
          'viewport': 'width=device-width, initial-scale=1.0' // Common default
      },

      // Additional Descriptive Fields
      additional: {
          'pageHref': href,
          'thumbnailPath': thumbnail,
          'imageLongDescription': imageLongDesc,
          'imageFileNameWithExt': `${imageFilename}.${imageExt}`,
          'imageFileSize': imageFileSize,
          'imageLicenseFull': imageLicense,
          'imageAttributionFull': imageAttribution,
          'imageDimensionsString': `${dimensions.width}x${dimensions.height}px`
      }
  };

  // Function to generate HTML meta tags from the metadata object
  function generateHTMLMetaTags(metaObj:any) {
      let html = '';
      
      // Open Graph Tags
      for (const [key, value] of Object.entries(metaObj.openGraph)) {
          html += `<meta property="${key}" content="${value}">\n`;
      }

      // Twitter Tags
      for (const [key, value] of Object.entries(metaObj.twitter)) {
          html += `<meta name="${key}" content="${value}">\n`;
      }

      // General Meta Tags
      for (const [key, value] of Object.entries(metaObj.meta)) {
          html += `<meta name="${key}" content="${value}">\n`;
      }

      // Schema.org JSON-LD
      html += `<script type="application/ld+json">\n${JSON.stringify(metaObj.schemaOrg, null, 2)}\n</script>\n`;

      return html;
  }

  // Return the metadata object and a method to generate HTML
  return {
      metadata: fullMetadata,
      toHTML: () => generateHTMLMetaTags(fullMetadata)
  };
}